const climateAverages = {
    chess_scape_rcp85_annual_tas_1980_avg: 8.47528374510115,
    chess_scape_rcp85_annual_tas_1980_min: 1.04769,
    chess_scape_rcp85_annual_tas_1980_max: 11.0153,
    chess_scape_rcp85_annual_tas_1990_avg: 8.77329950817331,
    chess_scape_rcp85_annual_tas_1990_min: 1.36195,
    chess_scape_rcp85_annual_tas_1990_max: 11.2391,
    chess_scape_rcp85_annual_tas_2000_avg: 9.24731057130236,
    chess_scape_rcp85_annual_tas_2000_min: 1.86478,
    chess_scape_rcp85_annual_tas_2000_max: 11.6976,
    chess_scape_rcp85_annual_tas_2010_avg: 9.45083736030772,
    chess_scape_rcp85_annual_tas_2010_min: 1.9962,
    chess_scape_rcp85_annual_tas_2010_max: 11.9502,
    chess_scape_rcp85_annual_tas_2020_avg: 10.0737514104584,
    chess_scape_rcp85_annual_tas_2020_min: 2.64733,
    chess_scape_rcp85_annual_tas_2020_max: 12.4881,
    chess_scape_rcp85_annual_tas_2030_avg: 10.2944398715469,
    chess_scape_rcp85_annual_tas_2030_min: 2.91392,
    chess_scape_rcp85_annual_tas_2030_max: 12.696,
    chess_scape_rcp85_annual_tas_2040_avg: 10.7703959912084,
    chess_scape_rcp85_annual_tas_2040_min: 3.40458,
    chess_scape_rcp85_annual_tas_2040_max: 13.0819,
    chess_scape_rcp85_annual_tas_2050_avg: 11.426687008625,
    chess_scape_rcp85_annual_tas_2050_min: 4.04458,
    chess_scape_rcp85_annual_tas_2050_max: 13.7254,
    chess_scape_rcp85_annual_tas_2060_avg: 11.8029520940965,
    chess_scape_rcp85_annual_tas_2060_min: 4.39527,
    chess_scape_rcp85_annual_tas_2060_max: 14.1668,
    chess_scape_rcp85_annual_tas_2070_avg: 12.5735615960183,
    chess_scape_rcp85_annual_tas_2070_min: 5.14747,
    chess_scape_rcp85_annual_tas_2070_max: 14.9363,
    chess_scape_rcp85_annual_tasmin_1980_avg: 5.58953403272489,
    chess_scape_rcp85_annual_tasmin_1980_min: -1.88624,
    chess_scape_rcp85_annual_tasmin_1980_max: 10.0635,
    chess_scape_rcp85_annual_tasmin_1990_avg: 5.89336926438006,
    chess_scape_rcp85_annual_tasmin_1990_min: -1.49876,
    chess_scape_rcp85_annual_tasmin_1990_max: 10.3007,
    chess_scape_rcp85_annual_tasmin_2000_avg: 6.32176833309953,
    chess_scape_rcp85_annual_tasmin_2000_min: -0.946792,
    chess_scape_rcp85_annual_tasmin_2000_max: 10.7653,
    chess_scape_rcp85_annual_tasmin_2010_avg: 6.49000698104093,
    chess_scape_rcp85_annual_tasmin_2010_min: -0.870628,
    chess_scape_rcp85_annual_tasmin_2010_max: 11.0266,
    chess_scape_rcp85_annual_tasmin_2020_avg: 7.06221200164058,
    chess_scape_rcp85_annual_tasmin_2020_min: -0.153141,
    chess_scape_rcp85_annual_tasmin_2020_max: 11.5085,
    chess_scape_rcp85_annual_tasmin_2030_avg: 7.30876380249533,
    chess_scape_rcp85_annual_tasmin_2030_min: 0.151038,
    chess_scape_rcp85_annual_tasmin_2030_max: 11.7303,
    chess_scape_rcp85_annual_tasmin_2040_avg: 7.77592994850284,
    chess_scape_rcp85_annual_tasmin_2040_min: 0.687533,
    chess_scape_rcp85_annual_tasmin_2040_max: 12.1186,
    chess_scape_rcp85_annual_tasmin_2050_avg: 8.35135460954092,
    chess_scape_rcp85_annual_tasmin_2050_min: 1.30675,
    chess_scape_rcp85_annual_tasmin_2050_max: 12.7186,
    chess_scape_rcp85_annual_tasmin_2060_avg: 8.69927780975524,
    chess_scape_rcp85_annual_tasmin_2060_min: 1.60563,
    chess_scape_rcp85_annual_tasmin_2060_max: 13.132,
    chess_scape_rcp85_annual_tasmin_2070_avg: 9.42789941573002,
    chess_scape_rcp85_annual_tasmin_2070_min: 2.37519,
    chess_scape_rcp85_annual_tasmin_2070_max: 13.8584,
    chess_scape_rcp85_annual_tasmax_1980_avg: 11.208425838,
    chess_scape_rcp85_annual_tasmax_1980_min: 3.4897,
    chess_scape_rcp85_annual_tasmax_1980_max: 13.8234,
    chess_scape_rcp85_annual_tasmax_1990_avg: 11.5102477636955,
    chess_scape_rcp85_annual_tasmax_1990_min: 3.7801,
    chess_scape_rcp85_annual_tasmax_1990_max: 14.0791,
    chess_scape_rcp85_annual_tasmax_2000_avg: 12.0499346302653,
    chess_scape_rcp85_annual_tasmax_2000_min: 4.31516,
    chess_scape_rcp85_annual_tasmax_2000_max: 14.6733,
    chess_scape_rcp85_annual_tasmax_2010_avg: 12.2908202198322,
    chess_scape_rcp85_annual_tasmax_2010_min: 4.46924,
    chess_scape_rcp85_annual_tasmax_2010_max: 14.9714,
    chess_scape_rcp85_annual_tasmax_2020_avg: 12.9831118789988,
    chess_scape_rcp85_annual_tasmax_2020_min: 5.15512,
    chess_scape_rcp85_annual_tasmax_2020_max: 15.6902,
    chess_scape_rcp85_annual_tasmax_2030_avg: 13.2041243580245,
    chess_scape_rcp85_annual_tasmax_2030_min: 5.4167,
    chess_scape_rcp85_annual_tasmax_2030_max: 15.9212,
    chess_scape_rcp85_annual_tasmax_2040_avg: 13.7136823704817,
    chess_scape_rcp85_annual_tasmax_2040_min: 5.91081,
    chess_scape_rcp85_annual_tasmax_2040_max: 16.4593,
    chess_scape_rcp85_annual_tasmax_2050_avg: 14.4598431231556,
    chess_scape_rcp85_annual_tasmax_2050_min: 6.59102,
    chess_scape_rcp85_annual_tasmax_2050_max: 17.3612,
    chess_scape_rcp85_annual_tasmax_2060_avg: 14.8732349749033,
    chess_scape_rcp85_annual_tasmax_2060_min: 6.98956,
    chess_scape_rcp85_annual_tasmax_2060_max: 17.8554,
    chess_scape_rcp85_annual_tasmax_2070_avg: 15.7073173901879,
    chess_scape_rcp85_annual_tasmax_2070_min: 7.78983,
    chess_scape_rcp85_annual_tasmax_2070_max: 18.8587,
    chess_scape_rcp85_annual_pr_1980_avg: 3.12600761770983,
    chess_scape_rcp85_annual_pr_1980_min: 1.27485,
    chess_scape_rcp85_annual_pr_1980_max: 16.7095,
    chess_scape_rcp85_annual_pr_1990_avg: 3.17201713007341,
    chess_scape_rcp85_annual_pr_1990_min: 1.28976,
    chess_scape_rcp85_annual_pr_1990_max: 17.5059,
    chess_scape_rcp85_annual_pr_2000_avg: 3.10733749515041,
    chess_scape_rcp85_annual_pr_2000_min: 1.27547,
    chess_scape_rcp85_annual_pr_2000_max: 17.1574,
    chess_scape_rcp85_annual_pr_2010_avg: 3.09405944704203,
    chess_scape_rcp85_annual_pr_2010_min: 1.27384,
    chess_scape_rcp85_annual_pr_2010_max: 16.3599,
    chess_scape_rcp85_annual_pr_2020_avg: 3.15801286810924,
    chess_scape_rcp85_annual_pr_2020_min: 1.22545,
    chess_scape_rcp85_annual_pr_2020_max: 17.7684,
    chess_scape_rcp85_annual_pr_2030_avg: 3.13991540548302,
    chess_scape_rcp85_annual_pr_2030_min: 1.28518,
    chess_scape_rcp85_annual_pr_2030_max: 17.4286,
    chess_scape_rcp85_annual_pr_2040_avg: 3.12487881622403,
    chess_scape_rcp85_annual_pr_2040_min: 1.23204,
    chess_scape_rcp85_annual_pr_2040_max: 17.683,
    chess_scape_rcp85_annual_pr_2050_avg: 3.1492945609377,
    chess_scape_rcp85_annual_pr_2050_min: 1.24736,
    chess_scape_rcp85_annual_pr_2050_max: 18.2482,
    chess_scape_rcp85_annual_pr_2060_avg: 3.10577328964043,
    chess_scape_rcp85_annual_pr_2060_min: 1.18563,
    chess_scape_rcp85_annual_pr_2060_max: 18.5732,
    chess_scape_rcp85_annual_pr_2070_avg: 3.12244003373237,
    chess_scape_rcp85_annual_pr_2070_min: 1.1363,
    chess_scape_rcp85_annual_pr_2070_max: 19.5621,
    chess_scape_rcp85_annual_rsds_1980_avg: 107.433988589176,
    chess_scape_rcp85_annual_rsds_1980_min: 65.762,
    chess_scape_rcp85_annual_rsds_1980_max: 142.825,
    chess_scape_rcp85_annual_rsds_1990_avg: 109.306657621648,
    chess_scape_rcp85_annual_rsds_1990_min: 66.5018,
    chess_scape_rcp85_annual_rsds_1990_max: 146.391,
    chess_scape_rcp85_annual_rsds_2000_avg: 113.987861575296,
    chess_scape_rcp85_annual_rsds_2000_min: 68.3858,
    chess_scape_rcp85_annual_rsds_2000_max: 151.895,
    chess_scape_rcp85_annual_rsds_2010_avg: 117.538580887377,
    chess_scape_rcp85_annual_rsds_2010_min: 69.6485,
    chess_scape_rcp85_annual_rsds_2010_max: 157.312,
    chess_scape_rcp85_annual_rsds_2020_avg: 120.454089580235,
    chess_scape_rcp85_annual_rsds_2020_min: 70.1293,
    chess_scape_rcp85_annual_rsds_2020_max: 161.611,
    chess_scape_rcp85_annual_rsds_2030_avg: 121.217473036307,
    chess_scape_rcp85_annual_rsds_2030_min: 70.4943,
    chess_scape_rcp85_annual_rsds_2030_max: 162.902,
    chess_scape_rcp85_annual_rsds_2040_avg: 122.648840500142,
    chess_scape_rcp85_annual_rsds_2040_min: 70.1574,
    chess_scape_rcp85_annual_rsds_2040_max: 165.714,
    chess_scape_rcp85_annual_rsds_2050_avg: 125.551325146916,
    chess_scape_rcp85_annual_rsds_2050_min: 71.0455,
    chess_scape_rcp85_annual_rsds_2050_max: 169.96,
    chess_scape_rcp85_annual_rsds_2060_avg: 126.307223404322,
    chess_scape_rcp85_annual_rsds_2060_min: 70.3954,
    chess_scape_rcp85_annual_rsds_2060_max: 170.829,
    chess_scape_rcp85_annual_rsds_2070_avg: 128.362633843231,
    chess_scape_rcp85_annual_rsds_2070_min: 70.3335,
    chess_scape_rcp85_annual_rsds_2070_max: 175.176,
    chess_scape_rcp85_annual_sfcWind_1980_avg: 4.41173348904725,
    chess_scape_rcp85_annual_sfcWind_1980_min: 1.17594,
    chess_scape_rcp85_annual_sfcWind_1980_max: 13.5319,
    chess_scape_rcp85_annual_sfcWind_1990_avg: 4.41681298078711,
    chess_scape_rcp85_annual_sfcWind_1990_min: 1.23604,
    chess_scape_rcp85_annual_sfcWind_1990_max: 13.5585,
    chess_scape_rcp85_annual_sfcWind_2000_avg: 4.36613048445579,
    chess_scape_rcp85_annual_sfcWind_2000_min: 1.21657,
    chess_scape_rcp85_annual_sfcWind_2000_max: 13.4869,
    chess_scape_rcp85_annual_sfcWind_2010_avg: 4.31962421626652,
    chess_scape_rcp85_annual_sfcWind_2010_min: 1.09444,
    chess_scape_rcp85_annual_sfcWind_2010_max: 13.4519,
    chess_scape_rcp85_annual_sfcWind_2020_avg: 4.38809480096352,
    chess_scape_rcp85_annual_sfcWind_2020_min: 1.20721,
    chess_scape_rcp85_annual_sfcWind_2020_max: 13.517,
    chess_scape_rcp85_annual_sfcWind_2030_avg: 4.33111179703276,
    chess_scape_rcp85_annual_sfcWind_2030_min: 1.12486,
    chess_scape_rcp85_annual_sfcWind_2030_max: 13.433,
    chess_scape_rcp85_annual_sfcWind_2040_avg: 4.27377116672442,
    chess_scape_rcp85_annual_sfcWind_2040_min: 1.08172,
    chess_scape_rcp85_annual_sfcWind_2040_max: 13.3713,
    chess_scape_rcp85_annual_sfcWind_2050_avg: 4.32729989462222,
    chess_scape_rcp85_annual_sfcWind_2050_min: 1.12998,
    chess_scape_rcp85_annual_sfcWind_2050_max: 13.4513,
    chess_scape_rcp85_annual_sfcWind_2060_avg: 4.27749073397191,
    chess_scape_rcp85_annual_sfcWind_2060_min: 1.0848,
    chess_scape_rcp85_annual_sfcWind_2060_max: 13.4242,
    chess_scape_rcp85_annual_sfcWind_2070_avg: 4.30544101384557,
    chess_scape_rcp85_annual_sfcWind_2070_min: 1.1046,
    chess_scape_rcp85_annual_sfcWind_2070_max: 13.5044,
    chess_scape_rcp85_summer_tas_1980_avg: 13.8239035641086,
    chess_scape_rcp85_summer_tas_1980_min: 6.20828,
    chess_scape_rcp85_summer_tas_1980_max: 16.2783,
    chess_scape_rcp85_summer_tas_1990_avg: 14.0009699773895,
    chess_scape_rcp85_summer_tas_1990_min: 6.34374,
    chess_scape_rcp85_summer_tas_1990_max: 16.5987,
    chess_scape_rcp85_summer_tas_2000_avg: 14.791174450165,
    chess_scape_rcp85_summer_tas_2000_min: 7.06852,
    chess_scape_rcp85_summer_tas_2000_max: 17.4274,
    chess_scape_rcp85_summer_tas_2010_avg: 15.1243978145042,
    chess_scape_rcp85_summer_tas_2010_min: 7.39537,
    chess_scape_rcp85_summer_tas_2010_max: 17.7914,
    chess_scape_rcp85_summer_tas_2020_avg: 15.8318843342573,
    chess_scape_rcp85_summer_tas_2020_min: 8.13814,
    chess_scape_rcp85_summer_tas_2020_max: 18.566,
    chess_scape_rcp85_summer_tas_2030_avg: 15.8833518586117,
    chess_scape_rcp85_summer_tas_2030_min: 8.14603,
    chess_scape_rcp85_summer_tas_2030_max: 18.6873,
    chess_scape_rcp85_summer_tas_2040_avg: 16.3894792217569,
    chess_scape_rcp85_summer_tas_2040_min: 8.63762,
    chess_scape_rcp85_summer_tas_2040_max: 19.2747,
    chess_scape_rcp85_summer_tas_2050_avg: 17.3859056339091,
    chess_scape_rcp85_summer_tas_2050_min: 9.63778,
    chess_scape_rcp85_summer_tas_2050_max: 20.4414,
    chess_scape_rcp85_summer_tas_2060_avg: 17.934506963267,
    chess_scape_rcp85_summer_tas_2060_min: 10.0981,
    chess_scape_rcp85_summer_tas_2060_max: 21.135,
    chess_scape_rcp85_summer_tas_2070_avg: 18.7440317641023,
    chess_scape_rcp85_summer_tas_2070_min: 10.9111,
    chess_scape_rcp85_summer_tas_2070_max: 22.0067,
    chess_scape_rcp85_summer_tasmin_1980_avg: 10.2477873737488,
    chess_scape_rcp85_summer_tasmin_1980_min: 3.04377,
    chess_scape_rcp85_summer_tasmin_1980_max: 15.1721,
    chess_scape_rcp85_summer_tasmin_1990_avg: 10.3964855281928,
    chess_scape_rcp85_summer_tasmin_1990_min: 3.24904,
    chess_scape_rcp85_summer_tasmin_1990_max: 15.5273,
    chess_scape_rcp85_summer_tasmin_2000_avg: 11.0295121274678,
    chess_scape_rcp85_summer_tasmin_2000_min: 3.81623,
    chess_scape_rcp85_summer_tasmin_2000_max: 16.2156,
    chess_scape_rcp85_summer_tasmin_2010_avg: 11.2609474193727,
    chess_scape_rcp85_summer_tasmin_2010_min: 3.95203,
    chess_scape_rcp85_summer_tasmin_2010_max: 16.5263,
    chess_scape_rcp85_summer_tasmin_2020_avg: 11.8890407981413,
    chess_scape_rcp85_summer_tasmin_2020_min: 4.72289,
    chess_scape_rcp85_summer_tasmin_2020_max: 17.1637,
    chess_scape_rcp85_summer_tasmin_2030_avg: 12.0384628573055,
    chess_scape_rcp85_summer_tasmin_2030_min: 4.85758,
    chess_scape_rcp85_summer_tasmin_2030_max: 17.2318,
    chess_scape_rcp85_summer_tasmin_2040_avg: 12.4459515916285,
    chess_scape_rcp85_summer_tasmin_2040_min: 5.27043,
    chess_scape_rcp85_summer_tasmin_2040_max: 17.6994,
    chess_scape_rcp85_summer_tasmin_2050_avg: 13.2606227274156,
    chess_scape_rcp85_summer_tasmin_2050_min: 5.98781,
    chess_scape_rcp85_summer_tasmin_2050_max: 18.6186,
    chess_scape_rcp85_summer_tasmin_2060_avg: 13.7350842031338,
    chess_scape_rcp85_summer_tasmin_2060_min: 6.37181,
    chess_scape_rcp85_summer_tasmin_2060_max: 19.1421,
    chess_scape_rcp85_summer_tasmin_2070_avg: 14.4303260532709,
    chess_scape_rcp85_summer_tasmin_2070_min: 7.03334,
    chess_scape_rcp85_summer_tasmin_2070_max: 19.8934,
    chess_scape_rcp85_summer_tasmax_1980_avg: 17.268971751738,
    chess_scape_rcp85_summer_tasmax_1980_min: 9.27115,
    chess_scape_rcp85_summer_tasmax_1980_max: 20.6868,
    chess_scape_rcp85_summer_tasmax_1990_avg: 17.4688860328047,
    chess_scape_rcp85_summer_tasmax_1990_min: 9.36428,
    chess_scape_rcp85_summer_tasmax_1990_max: 20.9854,
    chess_scape_rcp85_summer_tasmax_2000_avg: 18.396968940538,
    chess_scape_rcp85_summer_tasmax_2000_min: 10.2036,
    chess_scape_rcp85_summer_tasmax_2000_max: 22.0705,
    chess_scape_rcp85_summer_tasmax_2010_avg: 18.8146862237199,
    chess_scape_rcp85_summer_tasmax_2010_min: 10.6423,
    chess_scape_rcp85_summer_tasmax_2010_max: 22.4996,
    chess_scape_rcp85_summer_tasmax_2020_avg: 19.6061719026026,
    chess_scape_rcp85_summer_tasmax_2020_min: 11.3925,
    chess_scape_rcp85_summer_tasmax_2020_max: 23.4135,
    chess_scape_rcp85_summer_tasmax_2030_avg: 19.5959105644695,
    chess_scape_rcp85_summer_tasmax_2030_min: 11.3397,
    chess_scape_rcp85_summer_tasmax_2030_max: 23.5009,
    chess_scape_rcp85_summer_tasmax_2040_avg: 20.1972482684815,
    chess_scape_rcp85_summer_tasmax_2040_min: 11.8829,
    chess_scape_rcp85_summer_tasmax_2040_max: 24.2144,
    chess_scape_rcp85_summer_tasmax_2050_avg: 21.3865314092541,
    chess_scape_rcp85_summer_tasmax_2050_min: 12.8017,
    chess_scape_rcp85_summer_tasmax_2050_max: 25.5676,
    chess_scape_rcp85_summer_tasmax_2060_avg: 22.0005985717513,
    chess_scape_rcp85_summer_tasmax_2060_min: 13.0451,
    chess_scape_rcp85_summer_tasmax_2060_max: 26.3077,
    chess_scape_rcp85_summer_tasmax_2070_avg: 22.9521639089428,
    chess_scape_rcp85_summer_tasmax_2070_min: 13.7232,
    chess_scape_rcp85_summer_tasmax_2070_max: 27.4317,
    chess_scape_rcp85_summer_pr_1980_avg: 2.66328496156078,
    chess_scape_rcp85_summer_pr_1980_min: 1.29497,
    chess_scape_rcp85_summer_pr_1980_max: 11.1483,
    chess_scape_rcp85_summer_pr_1990_avg: 2.65817718257838,
    chess_scape_rcp85_summer_pr_1990_min: 1.29867,
    chess_scape_rcp85_summer_pr_1990_max: 10.5423,
    chess_scape_rcp85_summer_pr_2000_avg: 2.49652672788121,
    chess_scape_rcp85_summer_pr_2000_min: 1.20009,
    chess_scape_rcp85_summer_pr_2000_max: 10.3582,
    chess_scape_rcp85_summer_pr_2010_avg: 2.41978780003291,
    chess_scape_rcp85_summer_pr_2010_min: 1.18397,
    chess_scape_rcp85_summer_pr_2010_max: 9.95075,
    chess_scape_rcp85_summer_pr_2020_avg: 2.34119067851042,
    chess_scape_rcp85_summer_pr_2020_min: 1.08394,
    chess_scape_rcp85_summer_pr_2020_max: 10.6066,
    chess_scape_rcp85_summer_pr_2030_avg: 2.38961394784863,
    chess_scape_rcp85_summer_pr_2030_min: 0.966699,
    chess_scape_rcp85_summer_pr_2030_max: 10.9494,
    chess_scape_rcp85_summer_pr_2040_avg: 2.41581085348678,
    chess_scape_rcp85_summer_pr_2040_min: 1.1145,
    chess_scape_rcp85_summer_pr_2040_max: 10.4751,
    chess_scape_rcp85_summer_pr_2050_avg: 2.10070046213533,
    chess_scape_rcp85_summer_pr_2050_min: 0.938298,
    chess_scape_rcp85_summer_pr_2050_max: 10.1958,
    chess_scape_rcp85_summer_pr_2060_avg: 1.97239001584952,
    chess_scape_rcp85_summer_pr_2060_min: 0.781174,
    chess_scape_rcp85_summer_pr_2060_max: 10.4396,
    chess_scape_rcp85_summer_pr_2070_avg: 1.95220853455747,
    chess_scape_rcp85_summer_pr_2070_min: 0.733517,
    chess_scape_rcp85_summer_pr_2070_max: 9.85174,
    chess_scape_rcp85_summer_rsds_1980_avg: 185.002718965683,
    chess_scape_rcp85_summer_rsds_1980_min: 120.369,
    chess_scape_rcp85_summer_rsds_1980_max: 234.411,
    chess_scape_rcp85_summer_rsds_1990_avg: 188.192646133339,
    chess_scape_rcp85_summer_rsds_1990_min: 121.951,
    chess_scape_rcp85_summer_rsds_1990_max: 241.898,
    chess_scape_rcp85_summer_rsds_2000_avg: 198.777230499089,
    chess_scape_rcp85_summer_rsds_2000_min: 123.836,
    chess_scape_rcp85_summer_rsds_2000_max: 255.651,
    chess_scape_rcp85_summer_rsds_2010_avg: 206.267864209807,
    chess_scape_rcp85_summer_rsds_2010_min: 126.595,
    chess_scape_rcp85_summer_rsds_2010_max: 267.496,
    chess_scape_rcp85_summer_rsds_2020_avg: 211.163031318106,
    chess_scape_rcp85_summer_rsds_2020_min: 125.451,
    chess_scape_rcp85_summer_rsds_2020_max: 274.323,
    chess_scape_rcp85_summer_rsds_2030_avg: 210.262891872555,
    chess_scape_rcp85_summer_rsds_2030_min: 127.005,
    chess_scape_rcp85_summer_rsds_2030_max: 275.812,
    chess_scape_rcp85_summer_rsds_2040_avg: 214.02360412944,
    chess_scape_rcp85_summer_rsds_2040_min: 127.566,
    chess_scape_rcp85_summer_rsds_2040_max: 280.719,
    chess_scape_rcp85_summer_rsds_2050_avg: 222.060064051656,
    chess_scape_rcp85_summer_rsds_2050_min: 127.246,
    chess_scape_rcp85_summer_rsds_2050_max: 292.623,
    chess_scape_rcp85_summer_rsds_2060_avg: 223.862637136134,
    chess_scape_rcp85_summer_rsds_2060_min: 126.714,
    chess_scape_rcp85_summer_rsds_2060_max: 294.641,
    chess_scape_rcp85_summer_rsds_2070_avg: 227.74364236111,
    chess_scape_rcp85_summer_rsds_2070_min: 126.836,
    chess_scape_rcp85_summer_rsds_2070_max: 300.907,
    chess_scape_rcp85_summer_sfcWind_1980_avg: 3.82183088417066,
    chess_scape_rcp85_summer_sfcWind_1980_min: 0.618905,
    chess_scape_rcp85_summer_sfcWind_1980_max: 12.517,
    chess_scape_rcp85_summer_sfcWind_1990_avg: 3.76306774602794,
    chess_scape_rcp85_summer_sfcWind_1990_min: 0.66092,
    chess_scape_rcp85_summer_sfcWind_1990_max: 12.4502,
    chess_scape_rcp85_summer_sfcWind_2000_avg: 3.71223471603575,
    chess_scape_rcp85_summer_sfcWind_2000_min: 0.549341,
    chess_scape_rcp85_summer_sfcWind_2000_max: 12.4343,
    chess_scape_rcp85_summer_sfcWind_2010_avg: 3.68102902212643,
    chess_scape_rcp85_summer_sfcWind_2010_min: 0.558243,
    chess_scape_rcp85_summer_sfcWind_2010_max: 12.3717,
    chess_scape_rcp85_summer_sfcWind_2020_avg: 3.68777021009719,
    chess_scape_rcp85_summer_sfcWind_2020_min: 0.548294,
    chess_scape_rcp85_summer_sfcWind_2020_max: 12.4127,
    chess_scape_rcp85_summer_sfcWind_2030_avg: 3.69285838364612,
    chess_scape_rcp85_summer_sfcWind_2030_min: 0.566962,
    chess_scape_rcp85_summer_sfcWind_2030_max: 12.3817,
    chess_scape_rcp85_summer_sfcWind_2040_avg: 3.62324738789316,
    chess_scape_rcp85_summer_sfcWind_2040_min: 0.434219,
    chess_scape_rcp85_summer_sfcWind_2040_max: 12.2387,
    chess_scape_rcp85_summer_sfcWind_2050_avg: 3.56710513230354,
    chess_scape_rcp85_summer_sfcWind_2050_min: 0.454696,
    chess_scape_rcp85_summer_sfcWind_2050_max: 12.246,
    chess_scape_rcp85_summer_sfcWind_2060_avg: 3.53951487521221,
    chess_scape_rcp85_summer_sfcWind_2060_min: 0.393067,
    chess_scape_rcp85_summer_sfcWind_2060_max: 12.236,
    chess_scape_rcp85_summer_sfcWind_2070_avg: 3.49632781631618,
    chess_scape_rcp85_summer_sfcWind_2070_min: 0.422962,
    chess_scape_rcp85_summer_sfcWind_2070_max: 12.2248,
    chess_scape_rcp85_winter_tas_1980_avg: 3.51576568375129,
    chess_scape_rcp85_winter_tas_1980_min: -3.70035,
    chess_scape_rcp85_winter_tas_1980_max: 7.13539,
    chess_scape_rcp85_winter_tas_1990_avg: 3.93870316789744,
    chess_scape_rcp85_winter_tas_1990_min: -3.24767,
    chess_scape_rcp85_winter_tas_1990_max: 7.38938,
    chess_scape_rcp85_winter_tas_2000_avg: 4.31212054754342,
    chess_scape_rcp85_winter_tas_2000_min: -2.68206,
    chess_scape_rcp85_winter_tas_2000_max: 7.69181,
    chess_scape_rcp85_winter_tas_2010_avg: 4.19476511769237,
    chess_scape_rcp85_winter_tas_2010_min: -3.00012,
    chess_scape_rcp85_winter_tas_2010_max: 7.81553,
    chess_scape_rcp85_winter_tas_2020_avg: 5.03614371045971,
    chess_scape_rcp85_winter_tas_2020_min: -2.04632,
    chess_scape_rcp85_winter_tas_2020_max: 8.40138,
    chess_scape_rcp85_winter_tas_2030_avg: 5.03446674311379,
    chess_scape_rcp85_winter_tas_2030_min: -2.13659,
    chess_scape_rcp85_winter_tas_2030_max: 8.47266,
    chess_scape_rcp85_winter_tas_2040_avg: 5.40605210221004,
    chess_scape_rcp85_winter_tas_2040_min: -1.67433,
    chess_scape_rcp85_winter_tas_2040_max: 8.85348,
    chess_scape_rcp85_winter_tas_2050_avg: 5.95411200053834,
    chess_scape_rcp85_winter_tas_2050_min: -1.17142,
    chess_scape_rcp85_winter_tas_2050_max: 9.31379,
    chess_scape_rcp85_winter_tas_2060_avg: 6.22417853274604,
    chess_scape_rcp85_winter_tas_2060_min: -0.860991,
    chess_scape_rcp85_winter_tas_2060_max: 9.67401,
    chess_scape_rcp85_winter_tas_2070_avg: 6.85310977775944,
    chess_scape_rcp85_winter_tas_2070_min: -0.238568,
    chess_scape_rcp85_winter_tas_2070_max: 10.307,
    chess_scape_rcp85_winter_tasmin_1980_avg: 1.16884529085999,
    chess_scape_rcp85_winter_tasmin_1980_min: -6.8642,
    chess_scape_rcp85_winter_tasmin_1980_max: 6.02747,
    chess_scape_rcp85_winter_tasmin_1990_avg: 1.61588386816743,
    chess_scape_rcp85_winter_tasmin_1990_min: -6.36203,
    chess_scape_rcp85_winter_tasmin_1990_max: 6.27141,
    chess_scape_rcp85_winter_tasmin_2000_avg: 2.04024771106518,
    chess_scape_rcp85_winter_tasmin_2000_min: -5.50131,
    chess_scape_rcp85_winter_tasmin_2000_max: 6.57382,
    chess_scape_rcp85_winter_tasmin_2010_avg: 1.91574970132631,
    chess_scape_rcp85_winter_tasmin_2010_min: -5.95223,
    chess_scape_rcp85_winter_tasmin_2010_max: 6.72498,
    chess_scape_rcp85_winter_tasmin_2020_avg: 2.792496890146,
    chess_scape_rcp85_winter_tasmin_2020_min: -4.71436,
    chess_scape_rcp85_winter_tasmin_2020_max: 7.31546,
    chess_scape_rcp85_winter_tasmin_2030_avg: 2.79713936114822,
    chess_scape_rcp85_winter_tasmin_2030_min: -4.89465,
    chess_scape_rcp85_winter_tasmin_2030_max: 7.42139,
    chess_scape_rcp85_winter_tasmin_2040_avg: 3.21928425190891,
    chess_scape_rcp85_winter_tasmin_2040_min: -4.19852,
    chess_scape_rcp85_winter_tasmin_2040_max: 7.82217,
    chess_scape_rcp85_winter_tasmin_2050_avg: 3.76558345981508,
    chess_scape_rcp85_winter_tasmin_2050_min: -3.4659,
    chess_scape_rcp85_winter_tasmin_2050_max: 8.24975,
    chess_scape_rcp85_winter_tasmin_2060_avg: 4.06119888926811,
    chess_scape_rcp85_winter_tasmin_2060_min: -3.15655,
    chess_scape_rcp85_winter_tasmin_2060_max: 8.61528,
    chess_scape_rcp85_winter_tasmin_2070_avg: 4.67903969471784,
    chess_scape_rcp85_winter_tasmin_2070_min: -2.38357,
    chess_scape_rcp85_winter_tasmin_2070_max: 9.2408,
    chess_scape_rcp85_winter_tasmax_1980_avg: 5.54656200139781,
    chess_scape_rcp85_winter_tasmax_1980_min: -1.63775,
    chess_scape_rcp85_winter_tasmax_1980_max: 8.91116,
    chess_scape_rcp85_winter_tasmax_1990_avg: 5.96028277555794,
    chess_scape_rcp85_winter_tasmax_1990_min: -1.17859,
    chess_scape_rcp85_winter_tasmax_1990_max: 9.14196,
    chess_scape_rcp85_winter_tasmax_2000_avg: 6.34157090686204,
    chess_scape_rcp85_winter_tasmax_2000_min: -0.685036,
    chess_scape_rcp85_winter_tasmax_2000_max: 9.43719,
    chess_scape_rcp85_winter_tasmax_2010_avg: 6.23717543800932,
    chess_scape_rcp85_winter_tasmax_2010_min: -1.02311,
    chess_scape_rcp85_winter_tasmax_2010_max: 9.57598,
    chess_scape_rcp85_winter_tasmax_2020_avg: 7.09465686059416,
    chess_scape_rcp85_winter_tasmax_2020_min: -0.0765533,
    chess_scape_rcp85_winter_tasmax_2020_max: 10.1706,
    chess_scape_rcp85_winter_tasmax_2030_avg: 7.09814113965932,
    chess_scape_rcp85_winter_tasmax_2030_min: -0.150578,
    chess_scape_rcp85_winter_tasmax_2030_max: 10.2674,
    chess_scape_rcp85_winter_tasmax_2040_avg: 7.47457195619006,
    chess_scape_rcp85_winter_tasmax_2040_min: 0.247279,
    chess_scape_rcp85_winter_tasmax_2040_max: 10.6589,
    chess_scape_rcp85_winter_tasmax_2050_avg: 8.02701475347152,
    chess_scape_rcp85_winter_tasmax_2050_min: 0.64731,
    chess_scape_rcp85_winter_tasmax_2050_max: 11.063,
    chess_scape_rcp85_winter_tasmax_2060_avg: 8.29919335108237,
    chess_scape_rcp85_winter_tasmax_2060_min: 0.963962,
    chess_scape_rcp85_winter_tasmax_2060_max: 11.4633,
    chess_scape_rcp85_winter_tasmax_2070_avg: 8.96676613974897,
    chess_scape_rcp85_winter_tasmax_2070_min: 1.56153,
    chess_scape_rcp85_winter_tasmax_2070_max: 12.0826,
    chess_scape_rcp85_winter_pr_1980_avg: 3.63328578572769,
    chess_scape_rcp85_winter_pr_1980_min: 1.28938,
    chess_scape_rcp85_winter_pr_1980_max: 22.5313,
    chess_scape_rcp85_winter_pr_1990_avg: 3.83959220981663,
    chess_scape_rcp85_winter_pr_1990_min: 1.31433,
    chess_scape_rcp85_winter_pr_1990_max: 24.9586,
    chess_scape_rcp85_winter_pr_2000_avg: 3.73287084299582,
    chess_scape_rcp85_winter_pr_2000_min: 1.27488,
    chess_scape_rcp85_winter_pr_2000_max: 25.043,
    chess_scape_rcp85_winter_pr_2010_avg: 3.65215119582872,
    chess_scape_rcp85_winter_pr_2010_min: 1.33484,
    chess_scape_rcp85_winter_pr_2010_max: 22.5527,
    chess_scape_rcp85_winter_pr_2020_avg: 3.94530045780714,
    chess_scape_rcp85_winter_pr_2020_min: 1.35889,
    chess_scape_rcp85_winter_pr_2020_max: 25.519,
    chess_scape_rcp85_winter_pr_2030_avg: 3.8067846580993,
    chess_scape_rcp85_winter_pr_2030_min: 1.38928,
    chess_scape_rcp85_winter_pr_2030_max: 23.7499,
    chess_scape_rcp85_winter_pr_2040_avg: 3.78943762658441,
    chess_scape_rcp85_winter_pr_2040_min: 1.38163,
    chess_scape_rcp85_winter_pr_2040_max: 23.302,
    chess_scape_rcp85_winter_pr_2050_avg: 4.16641153382063,
    chess_scape_rcp85_winter_pr_2050_min: 1.46571,
    chess_scape_rcp85_winter_pr_2050_max: 26.1534,
    chess_scape_rcp85_winter_pr_2060_avg: 4.22878559548509,
    chess_scape_rcp85_winter_pr_2060_min: 1.51329,
    chess_scape_rcp85_winter_pr_2060_max: 26.5419,
    chess_scape_rcp85_winter_pr_2070_avg: 4.33009391177537,
    chess_scape_rcp85_winter_pr_2070_min: 1.51409,
    chess_scape_rcp85_winter_pr_2070_max: 27.9828,
    chess_scape_rcp85_winter_rsds_1980_avg: 31.1654845219273,
    chess_scape_rcp85_winter_rsds_1980_min: 13.5473,
    chess_scape_rcp85_winter_rsds_1980_max: 56.1496,
    chess_scape_rcp85_winter_rsds_1990_avg: 31.6409208287943,
    chess_scape_rcp85_winter_rsds_1990_min: 13.6664,
    chess_scape_rcp85_winter_rsds_1990_max: 56.5611,
    chess_scape_rcp85_winter_rsds_2000_avg: 32.872573978165,
    chess_scape_rcp85_winter_rsds_2000_min: 13.9993,
    chess_scape_rcp85_winter_rsds_2000_max: 57.1215,
    chess_scape_rcp85_winter_rsds_2010_avg: 33.741541301789,
    chess_scape_rcp85_winter_rsds_2010_min: 14.2135,
    chess_scape_rcp85_winter_rsds_2010_max: 59.0467,
    chess_scape_rcp85_winter_rsds_2020_avg: 34.0951425931045,
    chess_scape_rcp85_winter_rsds_2020_min: 14.4434,
    chess_scape_rcp85_winter_rsds_2020_max: 59.6758,
    chess_scape_rcp85_winter_rsds_2030_avg: 34.7231520359451,
    chess_scape_rcp85_winter_rsds_2030_min: 14.6503,
    chess_scape_rcp85_winter_rsds_2030_max: 65.9757,
    chess_scape_rcp85_winter_rsds_2040_avg: 35.091167936866,
    chess_scape_rcp85_winter_rsds_2040_min: 14.6083,
    chess_scape_rcp85_winter_rsds_2040_max: 65.9852,
    chess_scape_rcp85_winter_rsds_2050_avg: 34.6448927825386,
    chess_scape_rcp85_winter_rsds_2050_min: 14.7136,
    chess_scape_rcp85_winter_rsds_2050_max: 64.8266,
    chess_scape_rcp85_winter_rsds_2060_avg: 35.0220805225797,
    chess_scape_rcp85_winter_rsds_2060_min: 14.467,
    chess_scape_rcp85_winter_rsds_2060_max: 66.2209,
    chess_scape_rcp85_winter_rsds_2070_avg: 35.4937262125145,
    chess_scape_rcp85_winter_rsds_2070_min: 14.662,
    chess_scape_rcp85_winter_rsds_2070_max: 70.269,
    chess_scape_rcp85_winter_sfcWind_1980_avg: 4.9679572397759,
    chess_scape_rcp85_winter_sfcWind_1980_min: 1.68687,
    chess_scape_rcp85_winter_sfcWind_1980_max: 14.4609,
    chess_scape_rcp85_winter_sfcWind_1990_avg: 5.07791534195699,
    chess_scape_rcp85_winter_sfcWind_1990_min: 1.79303,
    chess_scape_rcp85_winter_sfcWind_1990_max: 14.5911,
    chess_scape_rcp85_winter_sfcWind_2000_avg: 5.0461972588346,
    chess_scape_rcp85_winter_sfcWind_2000_min: 1.82348,
    chess_scape_rcp85_winter_sfcWind_2000_max: 14.5614,
    chess_scape_rcp85_winter_sfcWind_2010_avg: 4.89220595855618,
    chess_scape_rcp85_winter_sfcWind_2010_min: 1.50142,
    chess_scape_rcp85_winter_sfcWind_2010_max: 14.349,
    chess_scape_rcp85_winter_sfcWind_2020_avg: 5.05221031031414,
    chess_scape_rcp85_winter_sfcWind_2020_min: 1.81454,
    chess_scape_rcp85_winter_sfcWind_2020_max: 14.5457,
    chess_scape_rcp85_winter_sfcWind_2030_avg: 4.92787017327298,
    chess_scape_rcp85_winter_sfcWind_2030_min: 1.60445,
    chess_scape_rcp85_winter_sfcWind_2030_max: 14.2857,
    chess_scape_rcp85_winter_sfcWind_2040_avg: 4.88232441090365,
    chess_scape_rcp85_winter_sfcWind_2040_min: 1.59874,
    chess_scape_rcp85_winter_sfcWind_2040_max: 14.2748,
    chess_scape_rcp85_winter_sfcWind_2050_avg: 5.10354240980574,
    chess_scape_rcp85_winter_sfcWind_2050_min: 1.71691,
    chess_scape_rcp85_winter_sfcWind_2050_max: 14.4975,
    chess_scape_rcp85_winter_sfcWind_2060_avg: 5.05006912752102,
    chess_scape_rcp85_winter_sfcWind_2060_min: 1.76413,
    chess_scape_rcp85_winter_sfcWind_2060_max: 14.5115,
    chess_scape_rcp85_winter_sfcWind_2070_avg: 5.11730909365009,
    chess_scape_rcp85_winter_sfcWind_2070_min: 1.73269,
    chess_scape_rcp85_winter_sfcWind_2070_max: 14.5701,
    chess_scape_rcp60_annual_tas_1980_avg: 8.47528374510115,
    chess_scape_rcp60_annual_tas_1980_min: 1.04769,
    chess_scape_rcp60_annual_tas_1980_max: 11.0153,
    chess_scape_rcp60_annual_tas_1990_avg: 8.77329950817331,
    chess_scape_rcp60_annual_tas_1990_min: 1.36195,
    chess_scape_rcp60_annual_tas_1990_max: 11.2391,
    chess_scape_rcp60_annual_tas_2000_avg: 9.24731057130236,
    chess_scape_rcp60_annual_tas_2000_min: 1.86478,
    chess_scape_rcp60_annual_tas_2000_max: 11.6976,
    chess_scape_rcp60_annual_tas_2010_avg: 9.51923636617864,
    chess_scape_rcp60_annual_tas_2010_min: 2.08474,
    chess_scape_rcp60_annual_tas_2010_max: 11.9676,
    chess_scape_rcp60_annual_tas_2020_avg: 9.75878367144803,
    chess_scape_rcp60_annual_tas_2020_min: 2.28744,
    chess_scape_rcp60_annual_tas_2020_max: 12.2449,
    chess_scape_rcp60_annual_tas_2030_avg: 9.85104496131819,
    chess_scape_rcp60_annual_tas_2030_min: 2.43369,
    chess_scape_rcp60_annual_tas_2030_max: 12.2748,
    chess_scape_rcp60_annual_tas_2040_avg: 10.4149319807255,
    chess_scape_rcp60_annual_tas_2040_min: 3.02322,
    chess_scape_rcp60_annual_tas_2040_max: 12.8255,
    chess_scape_rcp60_annual_tas_2050_avg: 10.4984632670857,
    chess_scape_rcp60_annual_tas_2050_min: 3.15964,
    chess_scape_rcp60_annual_tas_2050_max: 12.8373,
    chess_scape_rcp60_annual_tas_2060_avg: 10.9956949464198,
    chess_scape_rcp60_annual_tas_2060_min: 3.59838,
    chess_scape_rcp60_annual_tas_2060_max: 13.3374,
    chess_scape_rcp60_annual_tas_2070_avg: 11.172175922328,
    chess_scape_rcp60_annual_tas_2070_min: 3.80968,
    chess_scape_rcp60_annual_tas_2070_max: 13.482,
    chess_scape_rcp60_annual_tasmin_1980_avg: 5.58953403272489,
    chess_scape_rcp60_annual_tasmin_1980_min: -1.88624,
    chess_scape_rcp60_annual_tasmin_1980_max: 10.0635,
    chess_scape_rcp60_annual_tasmin_1990_avg: 5.89336926438006,
    chess_scape_rcp60_annual_tasmin_1990_min: -1.49876,
    chess_scape_rcp60_annual_tasmin_1990_max: 10.3007,
    chess_scape_rcp60_annual_tasmin_2000_avg: 6.32176833309953,
    chess_scape_rcp60_annual_tasmin_2000_min: -0.946792,
    chess_scape_rcp60_annual_tasmin_2000_max: 10.7653,
    chess_scape_rcp60_annual_tasmin_2010_avg: 6.54908572155035,
    chess_scape_rcp60_annual_tasmin_2010_min: -0.745825,
    chess_scape_rcp60_annual_tasmin_2010_max: 11.0338,
    chess_scape_rcp60_annual_tasmin_2020_avg: 6.7826060412321,
    chess_scape_rcp60_annual_tasmin_2020_min: -0.528172,
    chess_scape_rcp60_annual_tasmin_2020_max: 11.2942,
    chess_scape_rcp60_annual_tasmin_2030_avg: 6.89183996867456,
    chess_scape_rcp60_annual_tasmin_2030_min: -0.366184,
    chess_scape_rcp60_annual_tasmin_2030_max: 11.3269,
    chess_scape_rcp60_annual_tasmin_2040_avg: 7.3821125594398,
    chess_scape_rcp60_annual_tasmin_2040_min: 0.232962,
    chess_scape_rcp60_annual_tasmin_2040_max: 11.8482,
    chess_scape_rcp60_annual_tasmin_2050_avg: 7.53307759726563,
    chess_scape_rcp60_annual_tasmin_2050_min: 0.478565,
    chess_scape_rcp60_annual_tasmin_2050_max: 11.8902,
    chess_scape_rcp60_annual_tasmin_2060_avg: 7.96076432378853,
    chess_scape_rcp60_annual_tasmin_2060_min: 0.882605,
    chess_scape_rcp60_annual_tasmin_2060_max: 12.3387,
    chess_scape_rcp60_annual_tasmin_2070_avg: 8.13171354137973,
    chess_scape_rcp60_annual_tasmin_2070_min: 1.07486,
    chess_scape_rcp60_annual_tasmin_2070_max: 12.5137,
    chess_scape_rcp60_annual_tasmax_1980_avg: 11.208425838,
    chess_scape_rcp60_annual_tasmax_1980_min: 3.4897,
    chess_scape_rcp60_annual_tasmax_1980_max: 13.8234,
    chess_scape_rcp60_annual_tasmax_1990_avg: 11.5102477636955,
    chess_scape_rcp60_annual_tasmax_1990_min: 3.7801,
    chess_scape_rcp60_annual_tasmax_1990_max: 14.0791,
    chess_scape_rcp60_annual_tasmax_2000_avg: 12.0499346302653,
    chess_scape_rcp60_annual_tasmax_2000_min: 4.31516,
    chess_scape_rcp60_annual_tasmax_2000_max: 14.6733,
    chess_scape_rcp60_annual_tasmax_2010_avg: 12.3712534039765,
    chess_scape_rcp60_annual_tasmax_2010_min: 4.57178,
    chess_scape_rcp60_annual_tasmax_2010_max: 15.0371,
    chess_scape_rcp60_annual_tasmax_2020_avg: 12.6274260998585,
    chess_scape_rcp60_annual_tasmax_2020_min: 4.77291,
    chess_scape_rcp60_annual_tasmax_2020_max: 15.353,
    chess_scape_rcp60_annual_tasmax_2030_avg: 12.7079635761684,
    chess_scape_rcp60_annual_tasmax_2030_min: 4.90944,
    chess_scape_rcp60_annual_tasmax_2030_max: 15.3728,
    chess_scape_rcp60_annual_tasmax_2040_avg: 13.360760774486,
    chess_scape_rcp60_annual_tasmax_2040_min: 5.55457,
    chess_scape_rcp60_annual_tasmax_2040_max: 16.093,
    chess_scape_rcp60_annual_tasmax_2050_avg: 13.4022790651482,
    chess_scape_rcp60_annual_tasmax_2050_min: 5.63151,
    chess_scape_rcp60_annual_tasmax_2050_max: 16.0899,
    chess_scape_rcp60_annual_tasmax_2060_avg: 13.9764092714638,
    chess_scape_rcp60_annual_tasmax_2060_min: 6.12318,
    chess_scape_rcp60_annual_tasmax_2060_max: 16.8201,
    chess_scape_rcp60_annual_tasmax_2070_avg: 14.1659381976624,
    chess_scape_rcp60_annual_tasmax_2070_min: 6.33223,
    chess_scape_rcp60_annual_tasmax_2070_max: 17.0125,
    chess_scape_rcp60_annual_pr_1980_avg: 3.12600761770983,
    chess_scape_rcp60_annual_pr_1980_min: 1.27485,
    chess_scape_rcp60_annual_pr_1980_max: 16.7095,
    chess_scape_rcp60_annual_pr_1990_avg: 3.17201713007341,
    chess_scape_rcp60_annual_pr_1990_min: 1.28976,
    chess_scape_rcp60_annual_pr_1990_max: 17.5059,
    chess_scape_rcp60_annual_pr_2000_avg: 3.10733749515041,
    chess_scape_rcp60_annual_pr_2000_min: 1.27547,
    chess_scape_rcp60_annual_pr_2000_max: 17.1574,
    chess_scape_rcp60_annual_pr_2010_avg: 3.09462238864463,
    chess_scape_rcp60_annual_pr_2010_min: 1.2563,
    chess_scape_rcp60_annual_pr_2010_max: 17.1288,
    chess_scape_rcp60_annual_pr_2020_avg: 3.15783681621982,
    chess_scape_rcp60_annual_pr_2020_min: 1.25779,
    chess_scape_rcp60_annual_pr_2020_max: 17.0335,
    chess_scape_rcp60_annual_pr_2030_avg: 3.18313729844564,
    chess_scape_rcp60_annual_pr_2030_min: 1.28571,
    chess_scape_rcp60_annual_pr_2030_max: 17.1241,
    chess_scape_rcp60_annual_pr_2040_avg: 3.13066295729386,
    chess_scape_rcp60_annual_pr_2040_min: 1.23811,
    chess_scape_rcp60_annual_pr_2040_max: 17.6055,
    chess_scape_rcp60_annual_pr_2050_avg: 3.12003445649553,
    chess_scape_rcp60_annual_pr_2050_min: 1.23498,
    chess_scape_rcp60_annual_pr_2050_max: 17.8526,
    chess_scape_rcp60_annual_pr_2060_avg: 3.19136723163723,
    chess_scape_rcp60_annual_pr_2060_min: 1.26431,
    chess_scape_rcp60_annual_pr_2060_max: 18.3967,
    chess_scape_rcp60_annual_pr_2070_avg: 3.09035994030358,
    chess_scape_rcp60_annual_pr_2070_min: 1.22483,
    chess_scape_rcp60_annual_pr_2070_max: 17.9181,
    chess_scape_rcp60_annual_rsds_1980_avg: 107.433988589176,
    chess_scape_rcp60_annual_rsds_1980_min: 65.762,
    chess_scape_rcp60_annual_rsds_1980_max: 142.825,
    chess_scape_rcp60_annual_rsds_1990_avg: 109.306657621648,
    chess_scape_rcp60_annual_rsds_1990_min: 66.5018,
    chess_scape_rcp60_annual_rsds_1990_max: 146.391,
    chess_scape_rcp60_annual_rsds_2000_avg: 113.987861575296,
    chess_scape_rcp60_annual_rsds_2000_min: 68.3858,
    chess_scape_rcp60_annual_rsds_2000_max: 151.895,
    chess_scape_rcp60_annual_rsds_2010_avg: 117.023782870722,
    chess_scape_rcp60_annual_rsds_2010_min: 69.5553,
    chess_scape_rcp60_annual_rsds_2010_max: 156.342,
    chess_scape_rcp60_annual_rsds_2020_avg: 118.612555485038,
    chess_scape_rcp60_annual_rsds_2020_min: 69.551,
    chess_scape_rcp60_annual_rsds_2020_max: 158.755,
    chess_scape_rcp60_annual_rsds_2030_avg: 119.221238337517,
    chess_scape_rcp60_annual_rsds_2030_min: 70.1299,
    chess_scape_rcp60_annual_rsds_2030_max: 159.927,
    chess_scape_rcp60_annual_rsds_2040_avg: 121.963381667117,
    chess_scape_rcp60_annual_rsds_2040_min: 70.4119,
    chess_scape_rcp60_annual_rsds_2040_max: 163.909,
    chess_scape_rcp60_annual_rsds_2050_avg: 121.204073613477,
    chess_scape_rcp60_annual_rsds_2050_min: 69.929,
    chess_scape_rcp60_annual_rsds_2050_max: 163.551,
    chess_scape_rcp60_annual_rsds_2060_avg: 123.817932138899,
    chess_scape_rcp60_annual_rsds_2060_min: 70.7368,
    chess_scape_rcp60_annual_rsds_2060_max: 167.833,
    chess_scape_rcp60_annual_rsds_2070_avg: 124.410408442465,
    chess_scape_rcp60_annual_rsds_2070_min: 70.8631,
    chess_scape_rcp60_annual_rsds_2070_max: 168.438,
    chess_scape_rcp60_annual_sfcWind_1980_avg: 4.41173348904725,
    chess_scape_rcp60_annual_sfcWind_1980_min: 1.17594,
    chess_scape_rcp60_annual_sfcWind_1980_max: 13.5319,
    chess_scape_rcp60_annual_sfcWind_1990_avg: 4.41681298078711,
    chess_scape_rcp60_annual_sfcWind_1990_min: 1.23604,
    chess_scape_rcp60_annual_sfcWind_1990_max: 13.5585,
    chess_scape_rcp60_annual_sfcWind_2000_avg: 4.36613048445579,
    chess_scape_rcp60_annual_sfcWind_2000_min: 1.21657,
    chess_scape_rcp60_annual_sfcWind_2000_max: 13.4869,
    chess_scape_rcp60_annual_sfcWind_2010_avg: 4.36237742016156,
    chess_scape_rcp60_annual_sfcWind_2010_min: 1.17371,
    chess_scape_rcp60_annual_sfcWind_2010_max: 13.5331,
    chess_scape_rcp60_annual_sfcWind_2020_avg: 4.36653844668225,
    chess_scape_rcp60_annual_sfcWind_2020_min: 1.16362,
    chess_scape_rcp60_annual_sfcWind_2020_max: 13.4954,
    chess_scape_rcp60_annual_sfcWind_2030_avg: 4.35829982569769,
    chess_scape_rcp60_annual_sfcWind_2030_min: 1.15358,
    chess_scape_rcp60_annual_sfcWind_2030_max: 13.4509,
    chess_scape_rcp60_annual_sfcWind_2040_avg: 4.31630019411517,
    chess_scape_rcp60_annual_sfcWind_2040_min: 1.14705,
    chess_scape_rcp60_annual_sfcWind_2040_max: 13.4414,
    chess_scape_rcp60_annual_sfcWind_2050_avg: 4.29810793488127,
    chess_scape_rcp60_annual_sfcWind_2050_min: 1.12806,
    chess_scape_rcp60_annual_sfcWind_2050_max: 13.4161,
    chess_scape_rcp60_annual_sfcWind_2060_avg: 4.33997212295908,
    chess_scape_rcp60_annual_sfcWind_2060_min: 1.14691,
    chess_scape_rcp60_annual_sfcWind_2060_max: 13.4632,
    chess_scape_rcp60_annual_sfcWind_2070_avg: 4.26197723428515,
    chess_scape_rcp60_annual_sfcWind_2070_min: 1.0802,
    chess_scape_rcp60_annual_sfcWind_2070_max: 13.3907,
    chess_scape_rcp60_summer_tas_1980_avg: 13.8239035641086,
    chess_scape_rcp60_summer_tas_1980_min: 6.20828,
    chess_scape_rcp60_summer_tas_1980_max: 16.2783,
    chess_scape_rcp60_summer_tas_1990_avg: 14.0009699773895,
    chess_scape_rcp60_summer_tas_1990_min: 6.34374,
    chess_scape_rcp60_summer_tas_1990_max: 16.5987,
    chess_scape_rcp60_summer_tas_2000_avg: 14.791174450165,
    chess_scape_rcp60_summer_tas_2000_min: 7.06852,
    chess_scape_rcp60_summer_tas_2000_max: 17.4274,
    chess_scape_rcp60_summer_tas_2010_avg: 15.0204558828214,
    chess_scape_rcp60_summer_tas_2010_min: 7.26814,
    chess_scape_rcp60_summer_tas_2010_max: 17.7097,
    chess_scape_rcp60_summer_tas_2020_avg: 15.4575977899428,
    chess_scape_rcp60_summer_tas_2020_min: 7.74716,
    chess_scape_rcp60_summer_tas_2020_max: 18.1663,
    chess_scape_rcp60_summer_tas_2030_avg: 15.6170559746986,
    chess_scape_rcp60_summer_tas_2030_min: 7.97915,
    chess_scape_rcp60_summer_tas_2030_max: 18.2793,
    chess_scape_rcp60_summer_tas_2040_avg: 16.2022956977183,
    chess_scape_rcp60_summer_tas_2040_min: 8.46543,
    chess_scape_rcp60_summer_tas_2040_max: 19.0128,
    chess_scape_rcp60_summer_tas_2050_avg: 16.0862937878366,
    chess_scape_rcp60_summer_tas_2050_min: 8.32381,
    chess_scape_rcp60_summer_tas_2050_max: 18.9199,
    chess_scape_rcp60_summer_tas_2060_avg: 16.618250940587,
    chess_scape_rcp60_summer_tas_2060_min: 8.83647,
    chess_scape_rcp60_summer_tas_2060_max: 19.5902,
    chess_scape_rcp60_summer_tas_2070_avg: 16.9671489440128,
    chess_scape_rcp60_summer_tas_2070_min: 9.21837,
    chess_scape_rcp60_summer_tas_2070_max: 19.9479,
    chess_scape_rcp60_summer_tasmin_1980_avg: 10.2477873737488,
    chess_scape_rcp60_summer_tasmin_1980_min: 3.04377,
    chess_scape_rcp60_summer_tasmin_1980_max: 15.1721,
    chess_scape_rcp60_summer_tasmin_1990_avg: 10.3964855281928,
    chess_scape_rcp60_summer_tasmin_1990_min: 3.24904,
    chess_scape_rcp60_summer_tasmin_1990_max: 15.5273,
    chess_scape_rcp60_summer_tasmin_2000_avg: 11.0295121274678,
    chess_scape_rcp60_summer_tasmin_2000_min: 3.81623,
    chess_scape_rcp60_summer_tasmin_2000_max: 16.2156,
    chess_scape_rcp60_summer_tasmin_2010_avg: 11.1984992526531,
    chess_scape_rcp60_summer_tasmin_2010_min: 3.96372,
    chess_scape_rcp60_summer_tasmin_2010_max: 16.5033,
    chess_scape_rcp60_summer_tasmin_2020_avg: 11.5696539738114,
    chess_scape_rcp60_summer_tasmin_2020_min: 4.33069,
    chess_scape_rcp60_summer_tasmin_2020_max: 16.7914,
    chess_scape_rcp60_summer_tasmin_2030_avg: 11.745496046043,
    chess_scape_rcp60_summer_tasmin_2030_min: 4.58452,
    chess_scape_rcp60_summer_tasmin_2030_max: 16.9296,
    chess_scape_rcp60_summer_tasmin_2040_avg: 12.2339441633177,
    chess_scape_rcp60_summer_tasmin_2040_min: 5.02694,
    chess_scape_rcp60_summer_tasmin_2040_max: 17.5227,
    chess_scape_rcp60_summer_tasmin_2050_avg: 12.2467584110116,
    chess_scape_rcp60_summer_tasmin_2050_min: 5.07105,
    chess_scape_rcp60_summer_tasmin_2050_max: 17.4955,
    chess_scape_rcp60_summer_tasmin_2060_avg: 12.5866275012669,
    chess_scape_rcp60_summer_tasmin_2060_min: 5.41167,
    chess_scape_rcp60_summer_tasmin_2060_max: 17.9058,
    chess_scape_rcp60_summer_tasmin_2070_avg: 12.9423787786181,
    chess_scape_rcp60_summer_tasmin_2070_min: 5.67912,
    chess_scape_rcp60_summer_tasmin_2070_max: 18.2696,
    chess_scape_rcp60_summer_tasmax_1980_avg: 17.268971751738,
    chess_scape_rcp60_summer_tasmax_1980_min: 9.27115,
    chess_scape_rcp60_summer_tasmax_1980_max: 20.6868,
    chess_scape_rcp60_summer_tasmax_1990_avg: 17.4688860328047,
    chess_scape_rcp60_summer_tasmax_1990_min: 9.36428,
    chess_scape_rcp60_summer_tasmax_1990_max: 20.9854,
    chess_scape_rcp60_summer_tasmax_2000_avg: 18.396968940538,
    chess_scape_rcp60_summer_tasmax_2000_min: 10.2036,
    chess_scape_rcp60_summer_tasmax_2000_max: 22.0705,
    chess_scape_rcp60_summer_tasmax_2010_avg: 18.6692967635497,
    chess_scape_rcp60_summer_tasmax_2010_min: 10.4399,
    chess_scape_rcp60_summer_tasmax_2010_max: 22.3995,
    chess_scape_rcp60_summer_tasmax_2020_avg: 19.1859191280477,
    chess_scape_rcp60_summer_tasmax_2020_min: 11.0112,
    chess_scape_rcp60_summer_tasmax_2020_max: 22.9449,
    chess_scape_rcp60_summer_tasmax_2030_avg: 19.3213925126971,
    chess_scape_rcp60_summer_tasmax_2030_min: 11.2268,
    chess_scape_rcp60_summer_tasmax_2030_max: 23.0392,
    chess_scape_rcp60_summer_tasmax_2040_avg: 20.02166543284,
    chess_scape_rcp60_summer_tasmax_2040_min: 11.7002,
    chess_scape_rcp60_summer_tasmax_2040_max: 23.9194,
    chess_scape_rcp60_summer_tasmax_2050_avg: 19.7931980487389,
    chess_scape_rcp60_summer_tasmax_2050_min: 11.5151,
    chess_scape_rcp60_summer_tasmax_2050_max: 23.7083,
    chess_scape_rcp60_summer_tasmax_2060_avg: 20.5059582587626,
    chess_scape_rcp60_summer_tasmax_2060_min: 12.0914,
    chess_scape_rcp60_summer_tasmax_2060_max: 24.6725,
    chess_scape_rcp60_summer_tasmax_2070_avg: 20.8640623708435,
    chess_scape_rcp60_summer_tasmax_2070_min: 12.4553,
    chess_scape_rcp60_summer_tasmax_2070_max: 24.9878,
    chess_scape_rcp60_summer_pr_1980_avg: 2.66328496156078,
    chess_scape_rcp60_summer_pr_1980_min: 1.29497,
    chess_scape_rcp60_summer_pr_1980_max: 11.1483,
    chess_scape_rcp60_summer_pr_1990_avg: 2.65817718257838,
    chess_scape_rcp60_summer_pr_1990_min: 1.29867,
    chess_scape_rcp60_summer_pr_1990_max: 10.5423,
    chess_scape_rcp60_summer_pr_2000_avg: 2.49652672788121,
    chess_scape_rcp60_summer_pr_2000_min: 1.20009,
    chess_scape_rcp60_summer_pr_2000_max: 10.3582,
    chess_scape_rcp60_summer_pr_2010_avg: 2.43589115271972,
    chess_scape_rcp60_summer_pr_2010_min: 1.18192,
    chess_scape_rcp60_summer_pr_2010_max: 10.5602,
    chess_scape_rcp60_summer_pr_2020_avg: 2.42511114426888,
    chess_scape_rcp60_summer_pr_2020_min: 1.14986,
    chess_scape_rcp60_summer_pr_2020_max: 10.2788,
    chess_scape_rcp60_summer_pr_2030_avg: 2.4144633216152,
    chess_scape_rcp60_summer_pr_2030_min: 1.12192,
    chess_scape_rcp60_summer_pr_2030_max: 10.4727,
    chess_scape_rcp60_summer_pr_2040_avg: 2.3332206723273,
    chess_scape_rcp60_summer_pr_2040_min: 1.04416,
    chess_scape_rcp60_summer_pr_2040_max: 10.3475,
    chess_scape_rcp60_summer_pr_2050_avg: 2.47162260363953,
    chess_scape_rcp60_summer_pr_2050_min: 1.18495,
    chess_scape_rcp60_summer_pr_2050_max: 10.6784,
    chess_scape_rcp60_summer_pr_2060_avg: 2.21518623427737,
    chess_scape_rcp60_summer_pr_2060_min: 0.90674,
    chess_scape_rcp60_summer_pr_2060_max: 10.3751,
    chess_scape_rcp60_summer_pr_2070_avg: 2.23161008188287,
    chess_scape_rcp60_summer_pr_2070_min: 1.04107,
    chess_scape_rcp60_summer_pr_2070_max: 10.5102,
    chess_scape_rcp60_summer_rsds_1980_avg: 185.002718965683,
    chess_scape_rcp60_summer_rsds_1980_min: 120.369,
    chess_scape_rcp60_summer_rsds_1980_max: 234.411,
    chess_scape_rcp60_summer_rsds_1990_avg: 188.192646133339,
    chess_scape_rcp60_summer_rsds_1990_min: 121.951,
    chess_scape_rcp60_summer_rsds_1990_max: 241.898,
    chess_scape_rcp60_summer_rsds_2000_avg: 198.777230499089,
    chess_scape_rcp60_summer_rsds_2000_min: 123.836,
    chess_scape_rcp60_summer_rsds_2000_max: 255.651,
    chess_scape_rcp60_summer_rsds_2010_avg: 203.464990032315,
    chess_scape_rcp60_summer_rsds_2010_min: 125.606,
    chess_scape_rcp60_summer_rsds_2010_max: 262.175,
    chess_scape_rcp60_summer_rsds_2020_avg: 208.009193246727,
    chess_scape_rcp60_summer_rsds_2020_min: 125.724,
    chess_scape_rcp60_summer_rsds_2020_max: 270.648,
    chess_scape_rcp60_summer_rsds_2030_avg: 209.776525415351,
    chess_scape_rcp60_summer_rsds_2030_min: 126.68,
    chess_scape_rcp60_summer_rsds_2030_max: 272.642,
    chess_scape_rcp60_summer_rsds_2040_avg: 213.661319369797,
    chess_scape_rcp60_summer_rsds_2040_min: 127.214,
    chess_scape_rcp60_summer_rsds_2040_max: 278.611,
    chess_scape_rcp60_summer_rsds_2050_avg: 210.290788793945,
    chess_scape_rcp60_summer_rsds_2050_min: 126.517,
    chess_scape_rcp60_summer_rsds_2050_max: 276.006,
    chess_scape_rcp60_summer_rsds_2060_avg: 217.909664790566,
    chess_scape_rcp60_summer_rsds_2060_min: 128.591,
    chess_scape_rcp60_summer_rsds_2060_max: 288.319,
    chess_scape_rcp60_summer_rsds_2070_avg: 218.370649115883,
    chess_scape_rcp60_summer_rsds_2070_min: 127.041,
    chess_scape_rcp60_summer_rsds_2070_max: 287.174,
    chess_scape_rcp60_summer_sfcWind_1980_avg: 3.82183088417066,
    chess_scape_rcp60_summer_sfcWind_1980_min: 0.618905,
    chess_scape_rcp60_summer_sfcWind_1980_max: 12.517,
    chess_scape_rcp60_summer_sfcWind_1990_avg: 3.76306774602794,
    chess_scape_rcp60_summer_sfcWind_1990_min: 0.66092,
    chess_scape_rcp60_summer_sfcWind_1990_max: 12.4502,
    chess_scape_rcp60_summer_sfcWind_2000_avg: 3.71223471603575,
    chess_scape_rcp60_summer_sfcWind_2000_min: 0.549341,
    chess_scape_rcp60_summer_sfcWind_2000_max: 12.4343,
    chess_scape_rcp60_summer_sfcWind_2010_avg: 3.72079011486133,
    chess_scape_rcp60_summer_sfcWind_2010_min: 0.578132,
    chess_scape_rcp60_summer_sfcWind_2010_max: 12.4548,
    chess_scape_rcp60_summer_sfcWind_2020_avg: 3.69007705830233,
    chess_scape_rcp60_summer_sfcWind_2020_min: 0.541963,
    chess_scape_rcp60_summer_sfcWind_2020_max: 12.3828,
    chess_scape_rcp60_summer_sfcWind_2030_avg: 3.65218841500926,
    chess_scape_rcp60_summer_sfcWind_2030_min: 0.518644,
    chess_scape_rcp60_summer_sfcWind_2030_max: 12.3493,
    chess_scape_rcp60_summer_sfcWind_2040_avg: 3.65950367466514,
    chess_scape_rcp60_summer_sfcWind_2040_min: 0.532313,
    chess_scape_rcp60_summer_sfcWind_2040_max: 12.3655,
    chess_scape_rcp60_summer_sfcWind_2050_avg: 3.63166147907764,
    chess_scape_rcp60_summer_sfcWind_2050_min: 0.502666,
    chess_scape_rcp60_summer_sfcWind_2050_max: 12.2989,
    chess_scape_rcp60_summer_sfcWind_2060_avg: 3.6121443415574,
    chess_scape_rcp60_summer_sfcWind_2060_min: 0.493443,
    chess_scape_rcp60_summer_sfcWind_2060_max: 12.2666,
    chess_scape_rcp60_summer_sfcWind_2070_avg: 3.5994211355464,
    chess_scape_rcp60_summer_sfcWind_2070_min: 0.454539,
    chess_scape_rcp60_summer_sfcWind_2070_max: 12.2546,
    chess_scape_rcp60_winter_tas_1980_avg: 3.51576568375129,
    chess_scape_rcp60_winter_tas_1980_min: -3.70035,
    chess_scape_rcp60_winter_tas_1980_max: 7.13539,
    chess_scape_rcp60_winter_tas_1990_avg: 3.93870316789744,
    chess_scape_rcp60_winter_tas_1990_min: -3.24767,
    chess_scape_rcp60_winter_tas_1990_max: 7.38938,
    chess_scape_rcp60_winter_tas_2000_avg: 4.31212054754342,
    chess_scape_rcp60_winter_tas_2000_min: -2.68206,
    chess_scape_rcp60_winter_tas_2000_max: 7.69181,
    chess_scape_rcp60_winter_tas_2010_avg: 4.43742325393847,
    chess_scape_rcp60_winter_tas_2010_min: -2.74135,
    chess_scape_rcp60_winter_tas_2010_max: 7.90444,
    chess_scape_rcp60_winter_tas_2020_avg: 4.60222348087109,
    chess_scape_rcp60_winter_tas_2020_min: -2.55105,
    chess_scape_rcp60_winter_tas_2020_max: 8.09933,
    chess_scape_rcp60_winter_tas_2030_avg: 4.66317996079158,
    chess_scape_rcp60_winter_tas_2030_min: -2.48325,
    chess_scape_rcp60_winter_tas_2030_max: 8.14726,
    chess_scape_rcp60_winter_tas_2040_avg: 5.06014638223406,
    chess_scape_rcp60_winter_tas_2040_min: -2.05153,
    chess_scape_rcp60_winter_tas_2040_max: 8.5063,
    chess_scape_rcp60_winter_tas_2050_avg: 5.23511865867894,
    chess_scape_rcp60_winter_tas_2050_min: -1.70153,
    chess_scape_rcp60_winter_tas_2050_max: 8.63285,
    chess_scape_rcp60_winter_tas_2060_avg: 5.7935365274071,
    chess_scape_rcp60_winter_tas_2060_min: -1.41081,
    chess_scape_rcp60_winter_tas_2060_max: 9.24545,
    chess_scape_rcp60_winter_tas_2070_avg: 5.53501940720862,
    chess_scape_rcp60_winter_tas_2070_min: -1.50076,
    chess_scape_rcp60_winter_tas_2070_max: 8.99859,
    chess_scape_rcp60_winter_tasmin_1980_avg: 1.16884529085999,
    chess_scape_rcp60_winter_tasmin_1980_min: -6.8642,
    chess_scape_rcp60_winter_tasmin_1980_max: 6.02747,
    chess_scape_rcp60_winter_tasmin_1990_avg: 1.61588386816743,
    chess_scape_rcp60_winter_tasmin_1990_min: -6.36203,
    chess_scape_rcp60_winter_tasmin_1990_max: 6.27141,
    chess_scape_rcp60_winter_tasmin_2000_avg: 2.04024771106518,
    chess_scape_rcp60_winter_tasmin_2000_min: -5.50131,
    chess_scape_rcp60_winter_tasmin_2000_max: 6.57382,
    chess_scape_rcp60_winter_tasmin_2010_avg: 2.13441615178369,
    chess_scape_rcp60_winter_tasmin_2010_min: -5.66749,
    chess_scape_rcp60_winter_tasmin_2010_max: 6.81721,
    chess_scape_rcp60_winter_tasmin_2020_avg: 2.36518535492595,
    chess_scape_rcp60_winter_tasmin_2020_min: -5.32115,
    chess_scape_rcp60_winter_tasmin_2020_max: 7.04892,
    chess_scape_rcp60_winter_tasmin_2030_avg: 2.41936781818704,
    chess_scape_rcp60_winter_tasmin_2030_min: -5.27059,
    chess_scape_rcp60_winter_tasmin_2030_max: 7.06925,
    chess_scape_rcp60_winter_tasmin_2040_avg: 2.81300909769817,
    chess_scape_rcp60_winter_tasmin_2040_min: -4.74727,
    chess_scape_rcp60_winter_tasmin_2040_max: 7.44822,
    chess_scape_rcp60_winter_tasmin_2050_avg: 3.01367808771814,
    chess_scape_rcp60_winter_tasmin_2050_min: -4.25063,
    chess_scape_rcp60_winter_tasmin_2050_max: 7.60592,
    chess_scape_rcp60_winter_tasmin_2060_avg: 3.58872002703103,
    chess_scape_rcp60_winter_tasmin_2060_min: -3.92335,
    chess_scape_rcp60_winter_tasmin_2060_max: 8.10796,
    chess_scape_rcp60_winter_tasmin_2070_avg: 3.34471335567952,
    chess_scape_rcp60_winter_tasmin_2070_min: -3.92775,
    chess_scape_rcp60_winter_tasmin_2070_max: 7.99128,
    chess_scape_rcp60_winter_tasmax_1980_avg: 5.54656200139781,
    chess_scape_rcp60_winter_tasmax_1980_min: -1.63775,
    chess_scape_rcp60_winter_tasmax_1980_max: 8.91116,
    chess_scape_rcp60_winter_tasmax_1990_avg: 5.96028277555794,
    chess_scape_rcp60_winter_tasmax_1990_min: -1.17859,
    chess_scape_rcp60_winter_tasmax_1990_max: 9.14196,
    chess_scape_rcp60_winter_tasmax_2000_avg: 6.34157090686204,
    chess_scape_rcp60_winter_tasmax_2000_min: -0.685036,
    chess_scape_rcp60_winter_tasmax_2000_max: 9.43719,
    chess_scape_rcp60_winter_tasmax_2010_avg: 6.50557320286888,
    chess_scape_rcp60_winter_tasmax_2010_min: -0.717454,
    chess_scape_rcp60_winter_tasmax_2010_max: 9.68791,
    chess_scape_rcp60_winter_tasmax_2020_avg: 6.63255978658242,
    chess_scape_rcp60_winter_tasmax_2020_min: -0.594768,
    chess_scape_rcp60_winter_tasmax_2020_max: 9.8668,
    chess_scape_rcp60_winter_tasmax_2030_avg: 6.71660663224652,
    chess_scape_rcp60_winter_tasmax_2030_min: -0.504039,
    chess_scape_rcp60_winter_tasmax_2030_max: 9.90857,
    chess_scape_rcp60_winter_tasmax_2040_avg: 7.12693970247837,
    chess_scape_rcp60_winter_tasmax_2040_min: -0.0452637,
    chess_scape_rcp60_winter_tasmax_2040_max: 10.3002,
    chess_scape_rcp60_winter_tasmax_2050_avg: 7.31851402823714,
    chess_scape_rcp60_winter_tasmax_2050_min: 0.213982,
    chess_scape_rcp60_winter_tasmax_2050_max: 10.4076,
    chess_scape_rcp60_winter_tasmax_2060_avg: 7.88078399290212,
    chess_scape_rcp60_winter_tasmax_2060_min: 0.538144,
    chess_scape_rcp60_winter_tasmax_2060_max: 11.0046,
    chess_scape_rcp60_winter_tasmax_2070_avg: 7.60714989120623,
    chess_scape_rcp60_winter_tasmax_2070_min: 0.333988,
    chess_scape_rcp60_winter_tasmax_2070_max: 10.7628,
    chess_scape_rcp60_winter_pr_1980_avg: 3.63328578572769,
    chess_scape_rcp60_winter_pr_1980_min: 1.28938,
    chess_scape_rcp60_winter_pr_1980_max: 22.5313,
    chess_scape_rcp60_winter_pr_1990_avg: 3.83959220981663,
    chess_scape_rcp60_winter_pr_1990_min: 1.31433,
    chess_scape_rcp60_winter_pr_1990_max: 24.9586,
    chess_scape_rcp60_winter_pr_2000_avg: 3.73287084299582,
    chess_scape_rcp60_winter_pr_2000_min: 1.27488,
    chess_scape_rcp60_winter_pr_2000_max: 25.043,
    chess_scape_rcp60_winter_pr_2010_avg: 3.74653287258387,
    chess_scape_rcp60_winter_pr_2010_min: 1.304,
    chess_scape_rcp60_winter_pr_2010_max: 24.3664,
    chess_scape_rcp60_winter_pr_2020_avg: 3.7924852673043,
    chess_scape_rcp60_winter_pr_2020_min: 1.36425,
    chess_scape_rcp60_winter_pr_2020_max: 23.5305,
    chess_scape_rcp60_winter_pr_2030_avg: 3.76235571448823,
    chess_scape_rcp60_winter_pr_2030_min: 1.35227,
    chess_scape_rcp60_winter_pr_2030_max: 23.1433,
    chess_scape_rcp60_winter_pr_2040_avg: 3.8726009371556,
    chess_scape_rcp60_winter_pr_2040_min: 1.38975,
    chess_scape_rcp60_winter_pr_2040_max: 24.0719,
    chess_scape_rcp60_winter_pr_2050_avg: 3.66651298012312,
    chess_scape_rcp60_winter_pr_2050_min: 1.28864,
    chess_scape_rcp60_winter_pr_2050_max: 24.4248,
    chess_scape_rcp60_winter_pr_2060_avg: 4.13712975701815,
    chess_scape_rcp60_winter_pr_2060_min: 1.45148,
    chess_scape_rcp60_winter_pr_2060_max: 26.0811,
    chess_scape_rcp60_winter_pr_2070_avg: 3.86883491587722,
    chess_scape_rcp60_winter_pr_2070_min: 1.37261,
    chess_scape_rcp60_winter_pr_2070_max: 23.8276,
    chess_scape_rcp60_winter_rsds_1980_avg: 31.1654845219273,
    chess_scape_rcp60_winter_rsds_1980_min: 13.5473,
    chess_scape_rcp60_winter_rsds_1980_max: 56.1496,
    chess_scape_rcp60_winter_rsds_1990_avg: 31.6409208287943,
    chess_scape_rcp60_winter_rsds_1990_min: 13.6664,
    chess_scape_rcp60_winter_rsds_1990_max: 56.5611,
    chess_scape_rcp60_winter_rsds_2000_avg: 32.872573978165,
    chess_scape_rcp60_winter_rsds_2000_min: 13.9993,
    chess_scape_rcp60_winter_rsds_2000_max: 57.1215,
    chess_scape_rcp60_winter_rsds_2010_avg: 33.6074322168816,
    chess_scape_rcp60_winter_rsds_2010_min: 14.2396,
    chess_scape_rcp60_winter_rsds_2010_max: 58.7405,
    chess_scape_rcp60_winter_rsds_2020_avg: 33.6876521096451,
    chess_scape_rcp60_winter_rsds_2020_min: 14.2108,
    chess_scape_rcp60_winter_rsds_2020_max: 58.2031,
    chess_scape_rcp60_winter_rsds_2030_avg: 34.4673178363993,
    chess_scape_rcp60_winter_rsds_2030_min: 14.4704,
    chess_scape_rcp60_winter_rsds_2030_max: 62.326,
    chess_scape_rcp60_winter_rsds_2040_avg: 34.4133941490423,
    chess_scape_rcp60_winter_rsds_2040_min: 14.5777,
    chess_scape_rcp60_winter_rsds_2040_max: 63.5543,
    chess_scape_rcp60_winter_rsds_2050_avg: 35.3259535086091,
    chess_scape_rcp60_winter_rsds_2050_min: 14.6057,
    chess_scape_rcp60_winter_rsds_2050_max: 67.657,
    chess_scape_rcp60_winter_rsds_2060_avg: 34.6005803574094,
    chess_scape_rcp60_winter_rsds_2060_min: 14.5628,
    chess_scape_rcp60_winter_rsds_2060_max: 66.0817,
    chess_scape_rcp60_winter_rsds_2070_avg: 35.2295417350609,
    chess_scape_rcp60_winter_rsds_2070_min: 14.6883,
    chess_scape_rcp60_winter_rsds_2070_max: 64.1816,
    chess_scape_rcp60_winter_sfcWind_1980_avg: 4.9679572397759,
    chess_scape_rcp60_winter_sfcWind_1980_min: 1.68687,
    chess_scape_rcp60_winter_sfcWind_1980_max: 14.4609,
    chess_scape_rcp60_winter_sfcWind_1990_avg: 5.07791534195699,
    chess_scape_rcp60_winter_sfcWind_1990_min: 1.79303,
    chess_scape_rcp60_winter_sfcWind_1990_max: 14.5911,
    chess_scape_rcp60_winter_sfcWind_2000_avg: 5.0461972588346,
    chess_scape_rcp60_winter_sfcWind_2000_min: 1.82348,
    chess_scape_rcp60_winter_sfcWind_2000_max: 14.5614,
    chess_scape_rcp60_winter_sfcWind_2010_avg: 4.9893206865301,
    chess_scape_rcp60_winter_sfcWind_2010_min: 1.70165,
    chess_scape_rcp60_winter_sfcWind_2010_max: 14.5113,
    chess_scape_rcp60_winter_sfcWind_2020_avg: 4.95718652179932,
    chess_scape_rcp60_winter_sfcWind_2020_min: 1.63961,
    chess_scape_rcp60_winter_sfcWind_2020_max: 14.3769,
    chess_scape_rcp60_winter_sfcWind_2030_avg: 4.93309297192209,
    chess_scape_rcp60_winter_sfcWind_2030_min: 1.60566,
    chess_scape_rcp60_winter_sfcWind_2030_max: 14.292,
    chess_scape_rcp60_winter_sfcWind_2040_avg: 4.94810688484624,
    chess_scape_rcp60_winter_sfcWind_2040_min: 1.69337,
    chess_scape_rcp60_winter_sfcWind_2040_max: 14.3835,
    chess_scape_rcp60_winter_sfcWind_2050_avg: 4.85815008354813,
    chess_scape_rcp60_winter_sfcWind_2050_min: 1.65541,
    chess_scape_rcp60_winter_sfcWind_2050_max: 14.319,
    chess_scape_rcp60_winter_sfcWind_2060_avg: 5.10138155834326,
    chess_scape_rcp60_winter_sfcWind_2060_min: 1.74298,
    chess_scape_rcp60_winter_sfcWind_2060_max: 14.4923,
    chess_scape_rcp60_winter_sfcWind_2070_avg: 4.87410586453016,
    chess_scape_rcp60_winter_sfcWind_2070_min: 1.55382,
    chess_scape_rcp60_winter_sfcWind_2070_max: 14.2594,
}

export { climateAverages }
