import * as React from "react";
const SvgRain = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g transform="translate(-159.86 -43.52)">
      <circle
        style={{
          fill: "#115158",
          fillOpacity: 1,
          strokeWidth: 0.46499997,
        }}
        cx={175.29}
        cy={58.949}
        r={15.429}
      />
      <g
        style={{
          fill: "#fff",
          strokeWidth: 0.68478221,
        }}
      >
        <path
          d="M45.488 18.014C43.702 11.038 37.371 6 30 6c-8.822 0-16 7.178-16 16-4.411 0-8 3.589-8 8s3.589 8 8 8h32c5.514 0 10-4.486 10-10 0-5.684-4.787-10.301-10.512-9.986ZM46 34H14c-2.206 0-4-1.794-4-4s1.794-4 4-4c.506 0 1.005.097 1.482.288a1.998 1.998 0 0 0 2.712-2.202A12.006 12.006 0 0 1 18 22c0-6.617 5.383-12 12-12 6.052 0 11.168 4.528 11.9 10.534a2.002 2.002 0 0 0 2.513 1.687C48.37 21.139 52 24.174 52 28c0 3.309-2.691 6-6 6zM32 42a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V44a2 2 0 0 0-2-2zM24 42a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V44a2 2 0 0 0-2-2zM16 42a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V44a2 2 0 0 0-2-2zM40 42a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V44a2 2 0 0 0-2-2zM48 42a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V44a2 2 0 0 0-2-2z"
          style={{
            fill: "#fff",
            strokeWidth: 0.68478221,
          }}
          transform="matrix(.38638 0 0 .38638 163.24 46.512)"
        />
      </g>
    </g>
  </svg>
);
export default SvgRain;
