import * as React from "react";
const SvgCloudCover = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={116.629}
    height={116.629}
    viewBox="0 0 30.858 30.858"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        style={{
          opacity: 1,
          fill: "#115158",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.5,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        cx={15.429}
        cy={281.571}
        r={15.429}
        transform="translate(0 -266.142)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M24.424 11.837h-.043a3.465 3.465 0 0 0-4.5-2.437 3.925 3.925 0 0 0-3.499-2.11 3.934 3.934 0 0 0-3.935 3.808c-.809-.536-1.962-.77-3.115-.554-1.522.285-3.147 1.461-3.147 3.907 0 .216.017.431.052.642-1.786.172-3.187 1.674-3.187 3.495 0 1.937 1.583 3.513 3.53 3.513h11.587a3.525 3.525 0 0 0 3.516-3.24h2.741c1.946 0 3.53-1.575 3.53-3.512s-1.584-3.512-3.53-3.512zm-6.257 9.037H6.579a2.294 2.294 0 0 1-2.297-2.286c0-1.26 1.03-2.285 2.297-2.285h.463a.617.617 0 0 0 .51-.27.61.61 0 0 0 .061-.573 2.67 2.67 0 0 1-.196-1.01c0-2.075 1.499-2.58 2.143-2.7.753-.142 1.546-.009 2.088.295a3.516 3.516 0 0 0-2.342 3.304c0 1.937 1.584 3.512 3.53 3.512h7.61a2.295 2.295 0 0 1-2.28 2.013zm6.257-3.24H12.836a2.293 2.293 0 0 1-2.296-2.285c0-1.26 1.03-2.285 2.296-2.285h.463a.617.617 0 0 0 .511-.27.61.61 0 0 0 .06-.573 2.67 2.67 0 0 1-.196-1.01 2.705 2.705 0 0 1 2.709-2.695c1.192 0 2.231.76 2.586 1.892a.615.615 0 0 0 .907.343 2.224 2.224 0 0 1 3.363 1.742.615.615 0 0 0 .615.57h.57a2.293 2.293 0 0 1 2.297 2.286c0 1.26-1.03 2.286-2.297 2.286z"
        style={{
          fill: "#fff",
          strokeWidth: 0.26458332,
        }}
      />
    </g>
  </svg>
);
export default SvgCloudCover;
