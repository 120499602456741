import * as React from "react";
const SvgTempMax = (props) => (
<svg height="30.858mm" width="30.858mm" version="1.1" viewBox="0 0 30.858 30.858" xmlns="http://www.w3.org/2000/svg">
	<g transform="translate(0 -266.14)">
		<g transform="translate(-2.0522 -11.649)">
			<circle cx="17.481" cy="293.22" fill="#115158" r="15.429" strokeWidth=".465"/>
			<g fill="#fff" strokeWidth=".65596" transform="matrix(.40335 0 0 .40335 4.574 279.91)">
				<path d="m32 61.5a11.5 11.5 0 0 1-6.5-21v-31.5a6.5 6.5 0 0 1 13 0v31.52a11.5 11.5 0 0 1-6.5 21zm0-56a3.5 3.5 0 0 0-3.5 3.5v33.21l-0.75 0.44a8.5 8.5 0 1 0 8.5 0l-0.75-0.44v-33.21a3.5 3.5 0 0 0-3.5-3.5z"/>
				<path d="m35.5 43.94c-0.92571-0.53445-1.4971-1.5211-1.5-2.59l0.087852-33.321-4.2636 0.014641 0.1757 33.306c-0.0029 1.0689-0.57429 2.0555-1.5 2.59-6.204 3.5819-3.6599 13.061 3.5 13.061s9.704-9.4791 3.5-13.061z"/>
			</g>
			<path d="m7.2995 286.39c-0.048013-0.0479-0.075066-0.11308-0.075127-0.18098l-0.00164-1.8718c-1.292e-4 -0.14138 0.11435-0.25607 0.25577-0.25623l2.3592-2e-3 -0.00114-1.2468c-8.43e-5 -0.0937 0.051076-0.17999 0.13321-0.22494 0.082263-0.0449 0.18244-0.0413 0.26134 9e-3l3.7838 2.4351c0.07272 0.0465 0.11736 0.12798 0.11741 0.21505 8.3e-5 0.0869-0.04392 0.16811-0.11702 0.21526l-3.7794 2.4421c-0.07872 0.051-0.17901 0.0546-0.26133 0.01-0.082335-0.0449-0.13382-0.13103-0.13371-0.22462l-0.00123-1.2467-2.3591 2e-3c-0.067891 7e-5 -0.13311-0.0269-0.18114-0.0747z" fill="#fff" strokeWidth=".84782"/>
		</g>
	</g>
</svg>
);
export default SvgTempMax;
